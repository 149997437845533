exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-article-preview-tsx": () => import("./../../../src/pages/article_preview.tsx" /* webpackChunkName: "component---src-pages-article-preview-tsx" */),
  "component---src-pages-become-a-member-tsx": () => import("./../../../src/pages/become_a_member.tsx" /* webpackChunkName: "component---src-pages-become-a-member-tsx" */),
  "component---src-pages-become-a-writer-tsx": () => import("./../../../src/pages/become_a_writer.tsx" /* webpackChunkName: "component---src-pages-become-a-writer-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-latest-tsx": () => import("./../../../src/pages/latest.tsx" /* webpackChunkName: "component---src-pages-latest-tsx" */),
  "component---src-pages-popular-tsx": () => import("./../../../src/pages/popular.tsx" /* webpackChunkName: "component---src-pages-popular-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-searchresult-js": () => import("./../../../src/pages/searchresult.js" /* webpackChunkName: "component---src-pages-searchresult-js" */),
  "component---src-pages-shorts-tsx": () => import("./../../../src/pages/shorts.tsx" /* webpackChunkName: "component---src-pages-shorts-tsx" */),
  "component---src-pages-writers-tsx": () => import("./../../../src/pages/writers.tsx" /* webpackChunkName: "component---src-pages-writers-tsx" */),
  "component---templates-article-tsx": () => import("./../../../templates/article.tsx" /* webpackChunkName: "component---templates-article-tsx" */),
  "component---templates-categories-tsx": () => import("./../../../templates/categories.tsx" /* webpackChunkName: "component---templates-categories-tsx" */),
  "component---templates-private-tsx": () => import("./../../../templates/private.tsx" /* webpackChunkName: "component---templates-private-tsx" */),
  "component---templates-tags-tsx": () => import("./../../../templates/tags.tsx" /* webpackChunkName: "component---templates-tags-tsx" */),
  "component---templates-writers-tsx": () => import("./../../../templates/writers.tsx" /* webpackChunkName: "component---templates-writers-tsx" */)
}

