import * as React from 'react';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const Header: React.FC = () => {
  const [isMobile, setIsMobile] = React.useState<boolean>(true);
  // windowWidthの初期値についてはreturn参照。初回レンダリング時の挙動を制御するために-1にした。
  const [windowWidth, setWindowWidth] = React.useState<number>(-1);

  // gatsbyはssgだから、windowはuseEffect内で処理する必要がある。
  React.useEffect(() => {
    setWindowWidth(window.innerWidth);
    // onresizeがuseEffect内に記述されているが、初回レンダリング以降も発火する。
    window.onresize = () => {
      setWindowWidth(window.innerWidth);
    };
  }, []);

  React.useEffect(() => {
    // 1056は、DesktopHeaderの横幅
    if (windowWidth >= 1056) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [windowWidth]);

  // 三項演算子だと見づらいのでif文で分岐させた。
  if (windowWidth === -1) {
    return null;
  } else {
    return isMobile ? <MobileHeader /> : <DesktopHeader />;
  }
};

export default Header;
