import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import favicon from '../../../assets/images/favicon.svg';

const MobileHeader: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <MobileHeaderWrapper>
      <Favicon onClick={() => setIsMenuOpen(false)} to="/">
        <img src={favicon} alt="UmeeT" />
      </Favicon>
      <HamburgerWrapper onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <span className={isMenuOpen ? 'menu_top open_top' : 'menu_top '}></span>
        <span
          className={isMenuOpen ? 'menu_middle open_middle' : 'menu_middle '}
        ></span>
        <span
          className={isMenuOpen ? 'menu_bottom open_bottom' : 'menu_bottom '}
        ></span>
      </HamburgerWrapper>
      {isMenuOpen && (
        <DrawerMenuWrapper>
          <LinkComponent onClick={() => setIsMenuOpen(false)} to="/">
            トップ
          </LinkComponent>
          <LinkComponent onClick={() => setIsMenuOpen(false)} to="/about">
            UmeeTとは
          </LinkComponent>
          <LinkComponent onClick={() => setIsMenuOpen(false)} to="/shorts">
            ショート
          </LinkComponent>
          <LinkComponent onClick={() => setIsMenuOpen(false)} to="/popular">
            ランキング
          </LinkComponent>
          <LinkComponent onClick={() => setIsMenuOpen(false)} to="/writers">
            ライター
          </LinkComponent>
          <LinkComponent
            onClick={() => setIsMenuOpen(false)}
            to="/become_a_member"
          >
            メンバー募集
          </LinkComponent>
          <LinkComponent onClick={() => setIsMenuOpen(false)} to="/contact">
            お問い合わせ
          </LinkComponent>
        </DrawerMenuWrapper>
      )}
    </MobileHeaderWrapper>
  );
};

const DrawerMenuWrapper = styled.div`
  position: fixed;
  top: 60px;
  padding-top: 66px;
  z-index: 1003;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  animation: drawingMenu 0.1s ease both;
  @keyframes drawingMenu {
    0% {
      left: -100%;
    }
    25% {
      left: -75%;
    }
    50% {
      left: -50%;
    }
    75% {
      left: -25%;
    }
    100% {
      left: 0;
    }
  }
`;

const Favicon = styled(Link)`
  display: block;
  margin: 11px 11px;
  img {
    height: 38px;
    width: 38px;
  }
`;

const HamburgerWrapper = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 18px;
  top: 18px;
  .menu_top,
  .menu_middle,
  .menu_bottom {
    display: block;
    position: relative;
    left: 3px;
    width: 18px;
    height: 2px;
    background-color: #000;
    transition: 0.4s;
  }
  .menu_top {
    top: 6px;
  }
  .menu_middle {
    top: 9px;
  }
  .menu_bottom {
    top: 12px;
  }
  /* TODO: バツ印のスタイルの数値に根拠を持たせる。*/
  /* 現状、open_top / open_bottomのtopは見た目で調整している。 */
  .open_top {
    top: 6.5px;
    transform: translateY(5px) rotate(45deg);
  }
  .open_middle {
    opacity: 0;
  }
  .open_bottom {
    top: 11.5px;
    transform: translateY(-4px) rotate(-45deg);
  }
`;

const LinkComponent = styled(Link)`
  color: inherit;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  display: block;
  padding: 0 0 24px 42px;
`;

const MobileHeaderWrapper = styled.div`
  position: fixed;
  z-index: 1003;
  background: rgba(255, 255, 255, 0.9);
  width: 100vw;
  height: 60px;
  display: flex;
`;

export default MobileHeader;
