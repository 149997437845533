import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import arrow_red from '../../assets/images/arrow_red.svg';
import { COLOR } from '../../assets/css/_constants';
// -------------------------------------------------
//
interface StatelessSeeMoreBtnProps {
  to: string;
  text?: string;
}
interface StatefullSeeMoreBtnProps extends StatelessSeeMoreBtnProps {}
// -------------------------------------------------
//
const StatelessSeeMoreBtn: React.FC<StatelessSeeMoreBtnProps> = (props) => (
  <RedArrowBtn to={props.to}>
    {props.text ? props.text : 'もっと見る'}
  </RedArrowBtn>
);
// -------------------------------------------------
//
const RedArrowBtn = styled(Link)`
  display: block;
  margin: 12px 0 0 auto;
  text-decoration: none;
  text-align: right;
  color: ${COLOR.torchRed};
  font-weight: bold;
  &:hover {
    &::after {
      transform: translate(12px, 0);
    }
  }
  &::after {
    content: '';
    display: inline-block;
    width: 32px;
    height: 12px;
    margin-left: 12px;
    background-image: url(${arrow_red});
    background-size: contain;
    transition: all 0.25s;
  }
`;
// -------------------------------------------------
//
const StatefullSeeMoreBtn: React.FC<StatefullSeeMoreBtnProps> = (props) => {
  return <StatelessSeeMoreBtn {...props} />;
};
export default StatefullSeeMoreBtn;
