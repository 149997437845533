import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import favicon from '../../../assets/images/favicon.svg';
import SearchForm from '../../molecules/searchForm';
import searchIconBlack from '../../../assets/images/searchIconBlack.svg';

const DesktopHeader: React.FC = () => {
  const [isSearchShow, setIsSearchShow] = useState(false);
  const isSearchShowClick = () => {
    setIsSearchShow(!isSearchShow);
  };
  return (
    <DesktopHeaderWrapper>
      <Favicon to="/" onClick={() => setIsSearchShow(false)}>
        <img src={favicon} alt="UmeeT" />
      </Favicon>
      {isSearchShow ? (
        <SearchForm onClick={isSearchShowClick} />
      ) : (
        <>
          <LinksWrapper>
            <LinkComponent to="/">TOP</LinkComponent>
            <LinkComponent to="/about">ABOUT</LinkComponent>
            <LinkComponent to="/shorts">SHORTS</LinkComponent>
            <LinkComponent to="/popular">RANKING</LinkComponent>
            <LinkComponent to="/writers">WRITER</LinkComponent>
            <LinkComponent to="/become_a_member">RECRUIT</LinkComponent>
            <LinkComponent to="/contact">CONTACT</LinkComponent>
          </LinksWrapper>
          <StyledSearchIconBlack type="button" onClick={isSearchShowClick}>
            <img src={searchIconBlack} alt="検索アイコン" />
          </StyledSearchIconBlack>
        </>
      )}
    </DesktopHeaderWrapper>
  );
};

const DesktopHeaderWrapper = styled.div`
  position: fixed;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 1001;
  width: 1056px;
  border-radius: 35px;
  background: rgba(255, 255, 255, 0.9);
`;

const Favicon = styled(Link)`
  display: block;
  position: absolute;
  top: 13px;
  left: 48px;
  img {
    width: 44px;
    height: 44px;
  }
`;

const LinkComponent = styled(Link)`
  padding: 0 10px;
  color: inherit;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
`;

const LinksWrapper = styled.div`
  padding: 20px 0;
`;

const StyledSearchIconBlack = styled.button`
  display: block;
  position: absolute;
  top: 20px;
  right: 48px;
  img {
    width: 32px;
    height: 32px;
  }
`;

export default DesktopHeader;
