import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import summarize from '../../utils/summarize';

interface OgpProps {
  post?: GatsbyTypes.Maybe<
    Pick<GatsbyTypes.WpPost, 'title' | 'date' | 'databaseId' | 'content'> & {
      featuredImage?: GatsbyTypes.Maybe<{
        node?: GatsbyTypes.Maybe<Pick<GatsbyTypes.WpMediaItem, 'sourceUrl'>>;
      }>;
    }
  >;
}

const OGP: React.FC<OgpProps> = ({ post }) => {
  const { site } = useStaticQuery<GatsbyTypes.OGPQuery>(graphql`
    query OGP {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const title = site?.siteMetadata?.title || '東大UmeeT';
  const description = site?.siteMetadata?.description || '';
  const siteUrl = site?.siteMetadata?.siteUrl || '';

  if (post) {
    return (
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={summarize(post.content || '')} />
        <meta property="og:title" content={post.title || ''} />
        <meta
          property="og:description"
          content={summarize(post.content || '')}
        />
        <meta
          property="og:url"
          content={`${siteUrl}/article/${post.databaseId}/`}
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:site_name"
          content="UmeeT - 東大発オンラインメディア"
        />
        <meta
          property="og:image"
          content={
            post.featuredImage?.node?.sourceUrl || `${siteUrl}/default-ogp.png/`
          }
        />
        <meta
          property="og:image:alt"
          content={`「${post.title}」のカバー画像`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={`@UmeeT_media`} />
        <meta
          name="twitter:url"
          content={`${siteUrl}/article/${post.databaseId}/`}
        />
        <meta name="twitter:title" content={post.title || ''} />
        <meta
          name="twitter:description"
          content={summarize(post.content || '')}
        />
        <meta
          name="twitter:image"
          content={
            post.featuredImage?.node?.sourceUrl || `${siteUrl}/default-ogp.png/`
          }
        />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`${siteUrl}/`} />
      <meta property="og:type" content="website" />
      <meta
        property="og:site_name"
        content="UmeeT - 東大発オンラインメディア"
      />
      <meta property="og:image" content={`${siteUrl}/default-ogp.png/`} />
      <meta property="og:image:alt" content={`東大UmeeTトップのOGP画像`} />
      <meta property="fb:app_id" content="350868715835267" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={'@UmeeT_media'} />
      <meta name="twitter:url" content={`${siteUrl}/`} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${siteUrl}/default-ogp.png/`} />
    </Helmet>
  );
};

export default OGP;
