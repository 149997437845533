import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import SearchIconWhite from '../../assets/images/searchIconWhite.svg';
import CloseButton from '../../assets/images/close-button.svg';

const SearchForm = (props) => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <SearchFormWrapper>
        <input
          type="text"
          placeholder="キーワードを入力してください"
          value={value}
          onChange={handleChange}
          // TODO: 虫眼鏡ボタンを押したら、autoFocusがかかるようにしたい
          // ------------------------ chromeのクラッシュの原因 ------------------------ //
          // 下のコメントアウトを外すと、虫眼鏡ボタンを押したときにinputにautoFocusが効くようにになる
          // 問題点が2個
          // 1. 二回目の検索のときに日本語を入力するとchromeがクラッシュする(safariはクラッシュしない)
          // 2. 検索ボックスを閉じるためのバツボタンを押したあとに再び、虫眼鏡を推してもautoFocusはかからない
          // autoFocus={true}
          // ------------------------ chromeのクラッシュの原因 ------------------------ //
          onKeyPress={(e) => {
            if (e.code == 'Enter') {
              e.preventDefault();
              {
                props.onClick;
              }
              location.href = `/searchresult/?keyword=${encodeURIComponent(
                value
              )}`;
            }
          }}
        />
        <Link
          className="link-class"
          to={`/searchresult/?keyword=${encodeURIComponent(value)}`}
          onClick={props.onClick}
        >
          <img src={SearchIconWhite} alt="検索アイコン" />
          <p>検索</p>
        </Link>
        <button className="close-button" onClick={props.onClick}>
          <img src={CloseButton} alt="検索アイコン" />
        </button>
      </SearchFormWrapper>
    </>
  );
};

const SearchFormWrapper = styled.div`
  display: flex;
  position: relative;
  width: 516px;
  height: 70px;
  input {
    display: block;
    width: 480px;
    height: 54px;
    border-radius: 4px;
    background-color: #f2f2f2;
    font-size: 18px;
    margin: 7px 0px 7px 0px;
    padding-left: 10px;
  }
  .link-class {
    display: flex;
    align-items: center;
    background-color: #f50031;
    position: absolute;
    right: 40px;
    top: 13px;
    width: 84px;
    height: 44px;
    border-radius: 8px;
    img {
      margin: 0px 0px 0px 7px;
      width: 24px;
      height: 24px;
    }
    p {
      color: white;
      font-size: 16px;
      margin: 0px 0px 0px 7px;
    }
  }
  .close-button {
    display: flex;
    position: absolute;
    right: 5px;
    top: 23px;
    img {
      width: 24px;
      height: 24px;
    }
  }
`;

export default SearchForm;
