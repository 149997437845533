import * as React from 'react';
import styled from 'styled-components';
import { DEVICE, COLOR } from '../../assets/css/_constants';
// -------------------------------------------------
// Types
interface StatefullHeadingProps {
  title: string;
  subtitle: string;
  color: 'primary' | 'accent' | 'black' | string;
  type: 'horizontal' | 'vertical';
}
interface StatelessHeadingProps extends StatefullHeadingProps {}
// -------------------------------------------------
// DOM
const StatelessHeading: React.FC<StatelessHeadingProps> = (props) => (
  <>
    {props.type == 'vertical' ? (
      <HeadingVertical color={props.color}>
        <img src={props.title} alt={props.subtitle} />
        <h3>{props.subtitle}</h3>
      </HeadingVertical>
    ) : (
      <HeadingHorizontal color={props.color}>
        <img src={props.title} alt={props.subtitle} />
        <h3>{props.subtitle}</h3>
      </HeadingHorizontal>
    )}
  </>
);
// -------------------------------------------------
// Style
const HeadingVertical = styled.div`
  width: 100%;
  margin: 32px auto;
  text-align: center;
  img {
    height: 36px;
    @media ${DEVICE.mobile} {
      height: 32px;
    }
  }
  h3 {
    color: ${(props) => props.color};
    font-size: 16px;
    font-weight: bold;
  }
`;
const HeadingHorizontal = styled.div`
  display: flex;
  width: 100%;
  margin: 0 0 32px;
  border-bottom: 4px solid ${(props) => props.color};
  color: ${(props) => props.color};
  @media ${DEVICE.tablet} {
    margin: 0 auto 20px;
  }
  @media ${DEVICE.mobile} {
    margin: 32px 0;
    padding: auto;
  }
  img {
    margin: 0 0 12px 8px;
  }
  h3 {
    margin-left: 12px;
    font-size: 14px;
    font-weight: bold;
    line-height: 36px;
  }
`;
// -------------------------------------------------
// State
const StatefullHeading: React.FC<StatefullHeadingProps> = (props) => {
  let color;
  switch (props.color) {
    case 'primary':
      color = COLOR.torchRed;
      break;
    case 'accent':
      color = COLOR.pacificBlue;
      break;
    case 'black':
      color = COLOR.black_87;
      break;
    default:
      color = COLOR.black_87;
      break;
  }
  return <StatelessHeading {...props} color={color} />;
};
export default StatefullHeading;
