import * as React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import { DEVICE, COLOR } from '../../assets/css/_constants';
import shareFb_bl from '../../assets/images/share-fb_black.svg';
import shareTw_bl from '../../assets/images/share-tw_black.svg';
import shareIg_bl from '../../assets/images/share-ig_black.svg';
import footerLogo from '../../assets/images/logo-mono_black.svg';
import fullLogo from '../../assets/images/logo-full.svg';
import heading_aboutus from '../../assets/images/heading-about_us.svg';
import heading_note from '../../assets/images/heading-note.svg';
import Heading from '../atoms/Heading';
import SeeMore from '../atoms/SeeMoreBtn';
import { GatsbyImage } from 'gatsby-plugin-image';
// -------------------------------------------------
//
interface StatelessFooterProps {
  footerImgs: GatsbyTypes.FooterQuery;
  note_links: Array<string>;
}
// -------------------------------------------------
//
const StatelessFooter: React.FC<StatelessFooterProps> = (props) => (
  <Wrapper>
    <NoteSection>
      <Heading
        title={heading_note}
        subtitle="編集部からのお知らせ"
        color="accent"
        type="horizontal"
      />
      <NoteWrapper>
        {props.footerImgs.noticeImages.nodes.map((img, index: number) => (
          <NoteItem to={'/' + props.note_links[index]} key={index}>
            <GatsbyImage
              image={img.childImageSharp.gatsbyImageData}
              alt={props.note_links[index]}
              key={index}
            />
          </NoteItem>
        ))}
      </NoteWrapper>
    </NoteSection>

    <AboutSection>
      <Heading
        title={heading_aboutus}
        subtitle="私たちについて"
        color="black"
        type="vertical"
      />
      <AboutWrapper>
        <AboutLogo src={fullLogo} alt="UmeeTのロゴ" />
        <p>
          UmeeTは「挑戦する東大生」の面白さを発信する、東大発オンラインメディアです。魅力的な東大生・卒業生へのインタビューや海外での留学・休学体験記、自分の研究への愛や、東大生が主催するイベントやスタートアップなど、読み手にとってなにかの指針となるような情報が詰まっております。
        </p>
        <SeeMore to="/about" />
      </AboutWrapper>
    </AboutSection>

    <FooterLogo src={footerLogo} alt="UmeeTのロゴ" />
    <SNSIconWrapper>
      <a href="https://www.facebook.com/todaiumeet" target="blank">
        <img src={shareFb_bl} alt="facebookのアイコン" width={40} />
      </a>
      <a href="https://twitter.com/UmeeT_media" target="blank">
        <img src={shareTw_bl} alt="twitterのアイコン" width={40} />
      </a>
      <a href="https://www.instagram.com/umeet_media/" target="blank">
        <img src={shareIg_bl} alt="instagramのアイコン" width={40} />
      </a>
    </SNSIconWrapper>

    <LinkWrapper>
      <LinkItem to="/about">UmeeTとは</LinkItem>
      <LinkItem to="/writers">ライター</LinkItem>
      <LinkItem to="/become_a_writer">記事を書きませんか</LinkItem>
      <LinkItem to="/contact">お問い合わせ</LinkItem>
      <LinkItem to="/become_a_member">メンバー募集</LinkItem>
      <LinkItem to="/privacy_policy">プライバシーポリシー</LinkItem>
    </LinkWrapper>
    <CopyRight>©️ 2021 UmeeT</CopyRight>
  </Wrapper>
);
// -------------------------------------------------
//
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 64px 0 0;
  align-items: center;
  background-color: ${COLOR.snowWhite};
`;
const NoteSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 64px 0 32px calc((100vw - 960px) / 4);
  padding: 64px calc((100vw - 960px) / 2) 64px calc((100vw - 960px) / 4);
  background-color: #fff;
  border: 1px solid ${COLOR.black_12};
  border-right: 0;
  border-radius: 24px 0 0 24px;
  @media ${DEVICE.tablet} {
    width: 100%;
    margin: 0;
    padding: 32px 0;
    border: none;
    border-radius: 0;
  }
  @media ${DEVICE.mobile} {
    width: 100%;
    margin: 0;
    padding: 0 16px 32px;
    border: none;
    border-radius: 0;
  }
`;
const NoteWrapper = styled.div`
  display: flex;
  max-width: 960px;
  justify-content: space-between;
  @media ${DEVICE.desktop} {
    width: 960px;
  }
  @media ${DEVICE.tablet} {
    margin: auto;
    flex-direction: space-between;
  }
  @media ${DEVICE.mobile} {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    flex-direction: column;
  }
`;
const NoteItem = styled(Link)`
  border: none;
  min-width: 280px;
  @media ${DEVICE.tablet} {
    min-width: 185px;
    width: 210px;
  }
  @media ${DEVICE.mobile} {
    width: calc(100% - 32px);
    height: auto;
    margin: 16px auto;
  }
  &:nth-child(2n) {
    margin: 0 24px;
    @media ${DEVICE.tablet} {
      margin: 0 8px;
    }
    @media ${DEVICE.mobile} {
      margin: auto;
    }
  }
`;
const AboutSection = styled.div`
  margin: 64px auto;
  display: flex;
  flex-direction: column;
`;
const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 760px;
  padding: 64px;
  background-color: ${COLOR.white};
  border: 1px solid ${COLOR.black_12};
  border-radius: 24px;
  line-height: 28px;
  @media ${DEVICE.mobile} {
    width: 100%;
    padding: 32px;
    border: none;
    border-radius: 0;
  }
  & img {
    margin: 12px auto 32px;
    @media ${DEVICE.mobile} {
      width: 60%;
    }
  }
`;
const AboutLogo = styled.img`
  margin: 12px auto 32px;
  @media ${DEVICE.mobile} {
    width: 60%;
  }
`;
const FooterLogo = styled.img`
  margin: 40px auto;
`;
const SNSIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  a {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const LinkWrapper = styled.div`
  display: flex;
  margin: 26px -0.25rem;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  max-width: 760px;
  @media ${DEVICE.mobile} {
    width: calc(100% - 32px);
  }
`;
const LinkItem = styled(Link)`
  margin: 12px 0.25rem;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: ${COLOR.black_87};
  @media ${DEVICE.mobile} {
    font-size: 12px;
  }
`;
const CopyRight = styled.small`
  margin-bottom: 20px;
  font-size: 12px;
`;
// -------------------------------------------------
//
const StatefullFooter: React.FC = () => {
  const note_links = ['become_a_writer', 'become_a_member', 'contact'];
  const footerImgs = useStaticQuery<GatsbyTypes.FooterQuery>(graphql`
    query Footer {
      noticeImages: allFile(filter: { relativePath: { regex: "/note-/" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 320)
          }
        }
      }
    }
  `);

  return <StatelessFooter footerImgs={footerImgs} note_links={note_links} />;
};

export default StatefullFooter;
