import React from 'react';
import 'ress';
import '../assets/css/global.css';
import { Helmet } from 'react-helmet';
import Header from '../components/organisms/Header/Header';
import Footer from '../components/organisms/Footer';
import OGP from '../components/mixin/ogp';

const Layout = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  React.useEffect(() => {
    const installGoogleAds = () => {
      const elem = document.createElement('script');
      elem.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      elem.async = true;
      elem.defer = true;
      document.body.insertBefore(elem, document.body.firstChild);
    };
    installGoogleAds();
  }, []);
  return (
    <div style={{ backgroundColor: '#FAFAFA' }}>
      <Helmet
        htmlAttributes={{
          lang: 'ja',
        }}
      >
        <meta charSet="utf-8" />
      </Helmet>
      <OGP />

      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
