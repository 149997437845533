export const DEVICE = {
  mobile: `(max-width: 768px)`,
  tablet: `(min-width: 768px) and (max-width: 1024px)`,
  desktop: `(min-width: 1025px)`,
};

export const COLOR = {
  torchRed: '#F50031',
  pacificBlue: '#00A1E3',

  // black
  black_87: 'rgba(4,0,0,0.87)',
  black_60: 'rgba(4,0,0,0.60)',
  black_30: 'rgba(4,0,0,0.30)',
  black_12: 'rgba(4,0,0,0.12)',

  // gray
  gray_10: '#F2F2F2',

  // White
  white: '#FFFFFF',
  snowWhite: '#FAFAFA',
  whiteSmoke: '#F5F5F5',
};
