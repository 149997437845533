import striptags from 'striptags';

const summarize = (content: string): string => {
  const postContent = striptags(content).replace(/\r?\n/g, '').trim();
  return postContent.length <= 120
    ? postContent
    : postContent.slice(0, 120) + '...';
};

export default summarize;
